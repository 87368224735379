import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent {
  currentCategory: string = 'Customer'; // Default value is 'Customer'

  constructor() { }

  toggleCategory() {
    this.currentCategory = this.currentCategory === 'Business' ? 'Customer' : 'Business';
  }
}