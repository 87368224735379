import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/shared/services/event.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  id;
  subscription = new Subscription();
  data;
  imageBaseUrl;
  dateTimeResult;
  createForm;
  isSubmitted = false;
  @ViewChild('closeModal') private closeModal: ElementRef;

  constructor(
    private _eventService: EventService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._initForm();
    this._route.params.subscribe(
      (params: Params) => {
        this.id = params['id'];
        this.getStoreEventById(this.id);
      });
  }

  private getStoreEventById(id: any) {
    this.subscription.add(
      this._eventService.getStoreEventById(id).subscribe({
        next: (response) => {
          this.data = response;
          this.imageBaseUrl = response.images_base_path;
          this.dateTimeResult = formatEventDate(this.data.event_details.start_date_time, this.data.event_details.end_date_time);
          console.log("[getStoreEventById] response:", response)
        },
        error: (err) => {
          console.log("Error:", err);
        }
      })
    );
  }

  private _initForm() {
    this.createForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required])
    });
  }

  onDiscard() {
    this.closeModal.nativeElement.click();
    this.createForm.reset();
    this.isSubmitted = false;
  }

  onSubmit() {
    debugger;
    this.isSubmitted = true;
    if (!this.createForm.valid)
      return;
    let data = {
      event_id: this.id,
      full_name: this.createForm.value.name,
      email: this.createForm.value.email,
      registration_from: 2
    }
    this.addUserEventTracking(data);
  }

  private addUserEventTracking(data: any) {
    this.subscription.add(
      this._eventService.addUserEventTracking(data).subscribe({
        next: (result) => {
          this.closeModal.nativeElement.click();
          this.createForm.reset();
          this.isSubmitted = false;
          this.getStoreEventById(this.id);
        },
        error: (err) => {
          console.log('error :', err);
        }
      })
    );
  }
}

function formatEventDate(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Helper function to get day and month
  function getDayMonth(date) {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' }); // Get month name
    return { day, month };
  }

  const startInfo = getDayMonth(startDate);
  const endInfo = getDayMonth(endDate);

  // Format date range
  let dateRange;
  if (startInfo.month === endInfo.month && startInfo.day === endInfo.day) {
    dateRange = `${startInfo.day} ${startInfo.month}`;
  } else if (startInfo.month === endInfo.month) {
    dateRange = `${startInfo.day}-${endInfo.day} ${startInfo.month}`;
  } else {
    dateRange = `${startInfo.day} ${startInfo.month} - ${endInfo.day} ${endInfo.month}`;
  }

  // Format time range
  const startTime = formatTime(startDate);
  const endTime = formatTime(endDate);
  const timeRange = `${startTime} - ${endTime}`;

  return { dateRange, timeRange };
}

function formatTime(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) hours -= 12;
  if (hours === 0) hours = 12;

  return `${hours}:${minutes} ${period}`;
}