import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-business-page',
  templateUrl: './business-page.component.html',
  styleUrls: ['./business-page.component.scss']
})
export class BusinessPageComponent implements OnInit {
  activeIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.activeIndex = 0;
  }

  businessItems = [
    { title: 'Increase Customer Retention', description: 'Turn one-time buyers into loyal customers.' },
    { title: 'Boost Sales', description: 'Drive more sales with tailored rewards and offers.' },
    { title: 'Save Time', description: 'Effortlessly manage all loyalty aspects from one platform.' },
    { title: 'Data-Driven Decisions', description: 'Access real-time insights to refine your marketing strategy.' }
  ];

  images = [
    { src: '../../assets/pro.svg' },
    { src: '../../assets/pro-1.svg' },
    { src: '../../assets/pro-2.svg' },
    { src: '../../assets/pro-3.svg' }
  ];

  setActive(index: number) {
    this.activeIndex = index;
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      },
      1366: {
        items: 5
      }
    },
    nav: true
  }

}
