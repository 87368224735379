import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/shared/services/event.service';

@Component({
  selector: 'app-cancel-participation',
  templateUrl: './cancel-participation.component.html',
  styleUrls: ['./cancel-participation.component.scss']
})
export class CancelParticipationComponent implements OnInit {
  subscription = new Subscription();

  constructor(
    private _eventService: EventService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(
      (params: Params) => {
        let id = params['id'];
        let data = {
          id,
          status: 5
        }
        this.updateUserEventTracking(data);
      });
  }

  private updateUserEventTracking(data: any) {
    this.subscription.add(
      this._eventService.updateUserEventTracking(data).subscribe({
        next: (response) => {
          console.log("[getStoreEventById] response:", response)
        },
        error: (err) => {
          console.log("Error:", err);
        }
      })
    );
  }
}