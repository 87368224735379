<div class="product-detail-wrap">
    <div class="container">
        <div class="top-header">
            <div class="col-sm-4">
                <a href="#">
                    <img src=".././assets/clique-logo.svg" alt="clique">
                </a>
            </div>
        </div>

        <div class="col-sm-12 mt-5">
            <div class="row">
                <div class="col-md-12 col-lg-12 text-white">
                    <h1 style="text-align: center;">Privacy Policy</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 text-white" [innerHTML]="data">
                    
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>