<div class="carousel-container">
    <div class="Marquee">
        <div class="Marquee-content">
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
        </div>
    </div>
</div>

<div class="reward-wrap business-content">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="text-white">70% of your first-time customers never come back. Retain them with Clique.</h3>
            <p class="mb-0">Design personalized rewards and exclusive deals for your customers . Enjoy seamless shopping
                experiences and stay
                updated on upcoming events and promotions tailored just for you.</p>
        </div>
        <div class="img-wrap">
            <div class="box">
                <img src="../assets/logo/image-4.svg" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-5.svg" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-6.svg" alt="">
            </div>
        </div>
    </div>
</div>

<div class="how-it-works-wrapper">
    <div class="container">
        <div class="header-line mb-5">
            <h3>How It Works</h3>
            <p class="mb-0">Start managing your loyalty programs in just three simple steps.</p>
        </div>

        <div class="col-sm-12">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Sign Up as a Business</h3>
                    <p class="mb-0">Sign up through the app and submit for verification.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Choose your engagement strategy</h3>
                    <p class="mb-0">Create loyalty programs, memberships, events & giftcards.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Deploy and Get Insights</h3>
                    <p class="mb-0">Deploy the program and get insights on your customers</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="why-business-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Customers Love Us</h3>
            <p class="mb-0">Empowering store owners to manage loyalty programs with ease and precision.</p>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <div class="b-related">
                        <div class="inner-header-line mb-5 text-center">
                            <h3 class="">Loyalty Programs</h3>
                            <p class="mb-0">Seamlessly deploy cash-backs and reward programs</p>
                        </div>
                        <figure>
                            <img src="../assets/business-img-2.svg" alt="" class="img-fluid">
                        </figure>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <div class="d-flex flex-column h-100">
                        <div class="col-sm-12 h-50">
                            <div class="h--box">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="inner-header-line mb-5">
                                            <h3 class="">Events Management</h3>
                                            <p class="mb-0">Create Events at the store and Manage RSVPs.</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <img src="../assets/business-img-1.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 h-50 justify-content-end align-items-end d-flex">
                            <div class="h--box1">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <div class="inner-header-line mb-5">
                                            <h3 class="">Memberships</h3>
                                            <p class="mb-0">Create Memberships for long-term customer engagement.</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <img src="../assets/business-img-3.svg" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="why-choose-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Choose Us?</h3>
            <p class="mb-0">Key Features to Boost Your Business</p>
        </div>

        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-7">
                    <div class="list" *ngFor="let item of businessItems; let i = index" (click)="setActive(i)"
                        [class.active]="activeIndex === i">
                        <label>{{ item.title }}</label>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-5">
                    <div class="related-to-img">
                        <ul>
                            <li *ngFor="let image of images; let j = index">
                                <img src="{{ image.src }}" alt="" class="image-slide"
                                    [style.display]="activeIndex === j ? 'block' : 'none'" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="our-app-wrap">
        <div class="col-sm-12 d-flex flex-column flex-lg-row">
            <div class="col-sm-12 col-lg-6 c-height d-flex flex-end align-items-end">
                <img src="../assets/our-app.svg" class="img-fluid">
            </div>
            <div class="col-sm-12 col-lg-6 left-content">
                <h4>Download the App and Cultivate Customer Loyalty</h4>
                <p>Boost customer loyalty with our app. Create personalized rewards, engage customers, and drive repeat
                    visits
                    effortlessly.</p>
                <div class="d-flex flex-row gap-3">
                    <a href="#"><img src="../assets/g-play.svg"></a>
                    <a href="#"><img src="../assets/app-store.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="what-our-user-wrap">
    <div class="header-line mb-5">
        <p class="mb-0">Client Reviews</p>
        <h3 class="">What Our Clients Say About Us</h3>
    </div>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<div class="contact-us-wrap">
    <app-contact-us></app-contact-us>

    <div class="faq-wrap">
        <div class="container">
            <div class="header-line mb-5">
                <h3 class="">Frequently asked questions <br>( FAQ )</h3>
            </div>
            <div class="faq-contnt">

                <!-- Accordion Item 1 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            What types of rewards can I offer to my customers?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the first item's accordion body. It is shown by default, until the collapse
                            plugin adds the
                            appropriate classes that we use to style each element.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            How do I create a loyalty program on your platform?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the second item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Can I manage multiple stores with one account?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Is there a free trial available?
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How do I track the performance of my loyalty programs?
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            Can I create and manage events on your platform?
                        </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>