<div class="container-fluid px-0">
    <div class="banner-wrapper d-flex">
        <div class="container">
            <div
                class="header-nav d-flex flex-row justify-content-between justify-content-lg-center align-items-center my-3 my-lg-5">
                <div class="col-sm-4 logo"><img src=".././assets/clique-logo.svg" alt="clique"></div>
                <div class="select-option col-sm-4 justify-content-center d-flex align-items-center gap-3">
                    <label class="text-nowrap">Engage as</label> <button class="choose-category"
                        (click)="toggleCategory()">{{
                        currentCategory
                        }}</button>
                </div>
                <div class="col-sm-4 justify-content-end align-items-end d-none d-lg-flex">
                    <button class="download-btn">Download</button>
                </div>
            </div>
            <div class="banner-text-wrap">
                <div class="text-tap col-sm-5">

                    <div *ngIf="currentCategory === 'Customer'" class="custom-content">
                        <h3>Unlock Exclusive Rewards with Our Loyalty App
                        </h3>
                        <p>Enjoy personalized rewards, exclusive deals, and seamless loyalty program access from your
                            favorite
                            stores—all in one easy-to-use app.</p>
                    </div>

                    <div *ngIf="currentCategory === 'Business'" class="custom-content">
                        <h3>Attract And Grow Your Clique - A Loyal Customer Base</h3>
                        <p>Retain your customers by creating loyalty programs, giftcards and engage with your customers
                            like never before to drive
                            repeat business.</p>
                    </div>
                </div>
                <div class="banner-img col-sm-7">
                    <div class="frame-section">
                        <img src=".././assets/main-banner.svg" alt="clique" class="d-none d-lg-flex">
                        <img src=".././assets/main-banner.svg" alt="clique" class="m-banner d-flex d-lg-none">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="currentCategory == 'Business'">
        <app-business-page></app-business-page>
    </div>

    <div *ngIf="currentCategory == 'Customer'">
        <app-customer-page></app-customer-page>
    </div>