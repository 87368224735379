import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventService } from 'src/shared/services/event.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isSubmitted = false;
  createForm;
  subscription = new Subscription();

  constructor(private _eventService: EventService) { }

  ngOnInit(): void {
    this._initForm();
  }

  // form
  private _initForm() {
    this.createForm = new FormGroup({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
      inquiry: new FormControl(null, [Validators.required]),
    });
  }

  private clearForm() {
    this.isSubmitted = false;
    this.createForm.patchValue({
      first_name: null,
      last_name: null,
      mobile: null,
      email: null,
      message: null,
      inquiry: null,
    })
  }

  onSubmit() {
    debugger;
    this.isSubmitted = true;
    if (!this.createForm.valid)
      return;

    let form_data = new FormData();
    form_data.append('first_name', `${this.createForm.value.first_name}`);
    form_data.append('last_name', `${this.createForm.value.last_name}`);
    form_data.append('email', `${this.createForm.value.email}`);
    form_data.append('mobile', `${this.createForm.value.mobile}`);
    this.sendContactUsEmail(this.createForm.value);
  }

  private sendContactUsEmail(data: any) {
    this.subscription.add(
      this._eventService.sendContactUsEmail(data).subscribe({
        next: (response) => {
          this.clearForm();
          console.log("[sendContactUsEmail] response:", response);
        },
        error: (err) => {
          console.log("Error:", err);
        }
      })
    );
  }

}
