<div class="carousel-container">
    <div class="Marquee">
        <div class="Marquee-content">
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-11.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-13.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-7.svg" alt="Image 3"></div>
            <div class="Marquee-tag"><img src=".././assets/logo/logo-3.svg" alt="Image 3"></div>
        </div>
    </div>
</div>

<div class="how-it-works-wrapper">
    <div class="container">
        <div class="header-line mb-5">
            <h3>How It Works</h3>
            <p class="mb-0">Start managing your loyalty programs in just three simple steps.</p>
        </div>

        <div class="col-sm-12">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Join the Loyalty Program</h3>
                    <p class="mb-0">Sign up through the store's app and start earning points with your purchases.
                    </p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <!-- <owl-carousel-o [options]="RewardSlider1">
              <ng-template carouselSlide><img src="../assets/pro.svg" alt="" class="img-fluid"></ng-template>  
              <ng-template carouselSlide><img src="../assets/pro-1.svg" alt="" class="img-fluid"></ng-template>  
              <ng-template carouselSlide><img src="../assets/pro-2.svg" alt="" class="img-fluid"></ng-template>  
            </owl-carousel-o> -->
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Redeem Your Rewards</h3>
                    <p class="mb-0">Use your points to unlock discounts, freebies, and exclusive deals.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <!-- <owl-carousel-o [options]="RewardSlider2">
              <ng-template carouselSlide><img src="../assets/pro.svg" alt="" class="img-fluid"></ng-template>  
              <ng-template carouselSlide><img src="../assets/pro-1.svg" alt="" class="img-fluid"></ng-template>  
              <ng-template carouselSlide><img src="../assets/pro-2.svg" alt="" class="img-fluid"></ng-template>  
            </owl-carousel-o> -->
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Stay Updated</h3>
                    <p class="mb-0">Be the first to know about upcoming events and special promotions at your
                        favorite stores.
                    </p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <!-- <owl-carousel-o [options]="RewardSlider3">
            <ng-template carouselSlide><img src="../assets/pro.svg" alt="" class="img-fluid"></ng-template>  
            <ng-template carouselSlide><img src="../assets/pro-1.svg" alt="" class="img-fluid"></ng-template>  
            <ng-template carouselSlide><img src="../assets/pro-2.svg" alt="" class="img-fluid"></ng-template>  
          </owl-carousel-o> -->
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-1.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-2.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-3.svg" alt="" class="img-fluid"></div>
                            <div class="Marquee-tag"><img src="../assets/pro-4.svg" alt="" class="img-fluid"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="reward-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="text-white">Rewarding Your Loyalty, Elevating Your Experience</h3>
            <p class="mb-0">Unlock exclusive rewards, special deals, and effortlessly join loyalty programs from
                your
                favorite brands. Enjoy smooth shopping and stay informed about the latest events and promotions, all
                personalized just for you.</p>
        </div>
        <div class="img-wrap">
            <div class="box">
                <img src="../assets/logo/image.svg" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-1.svg" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-2.svg" alt="">
            </div>
        </div>

        <!-- <owl-carousel-o [options]="RewardSlider">
          <ng-template carouselSlide><img src="../assets/logo/image.svg" alt=""></ng-template>  
          <ng-template carouselSlide><img src="../assets/logo/image-1.svg" alt=""></ng-template>  
          <ng-template carouselSlide><img src="../assets/logo/image-2.svg" alt=""></ng-template>  
        </owl-carousel-o> -->
    </div>
</div>

<div class="why-customer-us">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Customers Love Us</h3>
            <p class="mb-0">Enhance Your Shopping Experience with These Features</p>
        </div>
        <div class="grid-container">
            <div class="box">
                <h3>Exclusive Rewards</h3>
                <p>Earn points and unlock unique offers.</p>
                <img src="../assets/image1.png" alt="">
            </div>
            <div class="box">
                <h3>Event Alerts</h3>
                <p>Stay updated on special events and promotions from your favorite stores.</p>
                <img src="../assets/image2.png" alt="">
            </div>
            <div class="box">
                <h3>Membership Benefits</h3>
                <p>Access exclusive memberships with additional perks.</p>
                <img src="../assets/image3.png" alt="">
            </div>
            <div class="box">
                <h3>Digital Gift Cards</h3>
                <p>Buy, send, and redeem gift cards instantly.</p>
                <img src="../assets/image4.png" alt="">
            </div>
        </div>
    </div>
</div>

<div class="why-choose-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Choose Us?</h3>
            <p class="mb-0">Key Features to Boost Your Business</p>
        </div>

        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-7">
                    <div class="list" *ngFor="let item of customerItems; let i = index" (click)="setActive(i)"
                        [class.active]="activeIndex === i">
                        <label>{{ item.title }}</label>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-5">
                    <div class="related-to-img">
                        <ul>
                            <li *ngFor="let image of images; let j = index">
                                <img src="{{ image.src }}" alt="" class="image-slide"
                                    [style.display]="activeIndex === j ? 'block' : 'none'" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="our-app-wrap">
        <div class="col-sm-12 d-flex flex-column flex-lg-row">
            <div class="col-sm-12 col-lg-6 c-height d-flex flex-end align-items-end">
                <img src="../assets/our-app.svg" class="img-fluid">
            </div>
            <div class="col-sm-12 col-lg-6 left-content">
                <h4>Start Saving with Our Loyalty App</h4>
                <p>Transform your shopping experience by earning rewards and staying in the loop with exclusive deals
                    from your favorite
                    stores.</p>
                <div class="d-flex flex-row gap-3">
                    <a href="#"><img src="../assets/g-play.svg"></a>
                    <a href="#"><img src="../assets/app-store.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="what-our-user-wrap">
    <div class="header-line mb-5">
        <p class="mb-0">User Reviews</p>
        <h3 class="">What Our Users Are Saying</h3>
    </div>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<div class="contact-us-wrap">
    <app-contact-us></app-contact-us>

    <div class="faq-wrap">
        <div class="container">
            <div class="header-line mb-5">
                <h3 class="">Frequently asked questions <br>( FAQ )</h3>
            </div>
            <div class="faq-contnt">

                <!-- Accordion Item 1 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How do I earn rewards using this app?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the first item's accordion body. It is shown by default, until the collapse
                            plugin adds the
                            appropriate classes that we use to style each element.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Can I manage multiple loyalty programs?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the second item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Is the app free to use?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Yes, downloading and using the app is completely free for customers.
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How do I redeem my rewards?
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            Will I get event notifications?
                        </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse
                            plugin adds the
                            appropriate classes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>