<div class="product-detail-wrap">
    <div class="container">
        <div class="top-header">
            <div class="col-sm-4"><img src=".././assets/clique-logo.svg" alt="clique"></div>
        </div>
        <div class="col-sm-12 mt-5">
            <div class="row">
                <div class="col-md-12 col-lg-5">
                    <div class="col-sm-12">
                        <img src="{{imageBaseUrl + (data?.event_details?.event_image ? data?.event_details?.event_image : '')}}"
                            alt="clique" class="w-100" />
                        <!-- <img src=".././assets/p-img.png"> -->
                    </div>
                    <div class="col-sm-12 mt-5 d-none d-lg-flex">
                        <div class="hosted-by">
                            <div class="d-flex flex-row justify-content-between align-items-center"><label
                                    class="text-white">Hosted
                                    By</label> <span class="rounded-5 bg-white text-black py-2 px-3 fw-semibold">
                                    {{data?.event_details?.hosted_by}}
                                </span>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center mt-3">
                                <label class="text-white">Going ({{data?.participants.length}})</label>
                                <span class="follow-user">
                                    <img src="{{imageBaseUrl + (data?.participants[0]?.profile_image ? data?.participants[0]?.profile_image : '')}}"
                                        class="img-1" *ngIf="data.participants.length > 0">
                                    <img src="{{imageBaseUrl + (data?.participants[1]?.profile_image ? data?.participants[1]?.profile_image : '')}}"
                                        class="img-2" *ngIf="data.participants.length > 1">
                                    <img src="{{imageBaseUrl + (data?.participants[2]?.profile_image ? data?.participants[2]?.profile_image : '')}}"
                                        class="img-3" *ngIf="data.participants.length > 2">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-7 item-detail">
                    <div class="w-100 d-flex flex-row gap-4 align-items-center i-name">
                        <h3 class="mb-0">
                            {{data?.event_details?.event_name}}
                        </h3> <span class="fs-3"><strong>€45</strong><sub>/
                                P</sub></span>
                    </div>
                    <div class="col-sm-12 d-flex flex-column mt-4 gap-3">
                        <div class="time-slot">
                            <span class="date">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.29175 10.0001C2.29175 15.7809 4.21925 17.7084 10.0001 17.7084C15.7809 17.7084 17.7084 15.7809 17.7084 10.0001C17.7084 4.21925 15.7809 2.29175 10.0001 2.29175C4.21925 2.29175 2.29175 4.21925 2.29175 10.0001Z"
                                        stroke="#00B97A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M12.8251 11.6818L9.99927 9.99598V6.36182" stroke="#00B97A"
                                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span class="date-show">
                                <label>{{dateTimeResult.dateRange}}</label>
                                <p>{{dateTimeResult.timeRange}}</p>
                            </span>
                        </div>
                        <div class="time-slot">
                            <span class="date">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.9999 11.6917C11.4358 11.6917 12.5999 10.5276 12.5999 9.0917C12.5999 7.65576 11.4358 6.4917 9.9999 6.4917C8.56396 6.4917 7.3999 7.65576 7.3999 9.0917C7.3999 10.5276 8.56396 11.6917 9.9999 11.6917Z"
                                        stroke="#00B97A" stroke-width="1.5" />
                                    <path
                                        d="M3.01675 7.57508C4.65842 0.358417 15.3501 0.36675 16.9834 7.58342C17.9417 11.8167 15.3084 15.4001 13.0001 17.6168C11.3251 19.2334 8.67508 19.2334 6.99175 17.6168C4.69175 15.4001 2.05842 11.8084 3.01675 7.57508Z"
                                        stroke="#00B97A" stroke-width="1.5" />
                                </svg>
                            </span>
                            <span class="date-show">
                                <label>{{data?.event_details?.event_address}}</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-12 mt-4">
                        <button class="request-join-btn" data-bs-toggle="modal" data-bs-target="#addModal">Request to
                            join</button>
                    </div>

                    <!-- <div class="col-sm-12 mt-4 d-flex d-lg-none">
                        <div class="hosted-by">
                            <div class="d-flex flex-row justify-content-between align-items-center"><label
                                    class="text-white">Hosted
                                    By</label> <span class="rounded-5 bg-white text-black py-2 px-3 fw-semibold">Steven
                                    Hawkins</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center mt-3">
                                <label class="text-white">Going (24)</label>
                                <span class="follow-user">
                                    <img src="../assets/oval.png" class="img-1">
                                    <img src="../assets/oval-1.png" class="img-2">
                                    <img src="../assets/oval-2.png" class="img-3">
                                </span>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-sm-12 related-content mt-5">
                        <label class="fw-semibold">About Event</label>
                        <p class="mt-3">{{data?.event_details?.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>

<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: black !important;">
                <button type="button" class="btn-close" data-bs-dismiss="modal" #closeModal aria-label="Close"
                    style="color: white !important;">X</button>
            </div>

            <div class="modal-body" style="background-color: black !important;">
                <form id="kt_account_profile_details_form" [formGroup]="createForm" class="form">
                    <!--begin::Card body-->
                    <div class="card-body p-9">
                        <!--begin::Input group-->
                        <div class="row mb-6">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-4">
                                <h3 style="color: white !important;">Your Info</h3>
                            </div>

                        </div>

                        <div class="row mb-6">
                            <div class="col-lg-4"></div>
                            <label class="col-lg-4 col-form-label fw-semibold fs-6"
                                style="color: white !important;">Name*</label>
                        </div>

                        <div class="row">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-4 fv-row">
                                <input type="text" class="form-control form-control-lg mb-3 mb-lg-0"
                                    placeholder="Enter Name" formControlName="name" />

                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.name.hasError('required')">
                                    Name is required.
                                </p>
                            </div>
                        </div>
                        <!--end::Input group-->
                        <br>

                        <!--begin::Input group-->
                        <div class="row mb-6">
                            <div class="col-lg-4"></div>
                            <label class="col-lg-4 col-form-label fw-semibold fs-6"
                                style="color: white !important;">Email*</label>
                        </div>

                        <div class="row">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-4 fv-row">
                                <input type="text" class="form-control form-control-lg mb-3 mb-lg-0"
                                    placeholder="Enter Email" formControlName="email" />

                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.email.hasError('required')">
                                    Email is required.
                                </p>
                            </div>
                        </div>
                        <br>

                        <div class="row mb-6">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-4">
                                <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
                            </div>
                        </div>
                        <!--end::Input group-->
                    </div>
                    <!--end::Card body-->
                    <!--end::Input group-->
                </form>
            </div>

            <!-- <div class="modal-footer">
                <button #closeModal type="button" class="btn btn-secondary" (click)="onDiscard()"
                    data-bs-dismiss="modal">Close</button>
            </div> -->
        </div>
    </div>
</div>